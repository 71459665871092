import React, { Component, Suspense,  useState, useEffect} from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./scss/style.scss";
import PrivateRoute from "../src/helper/privateroute";
import WebStoryCreatornew from "../src/components/AppContent";
import { BalanceProvider } from "./contexts/BalanceContext";
import { useKeycloak } from '@react-keycloak/web'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

const Login = React.lazy(() => import("./views/pages/login/Login"));

function App() {
  const { keycloak, initialized } = useKeycloak();
  const [, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleNetworkChange = () => {
      setIsOnline(window.navigator.onLine);

      if (!window.navigator.onLine) {
        handleOfflineMode();
      }
    };

    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
    };
  }, [keycloak, initialized]);

  const handleOfflineMode = () => {
    keycloak.logout({ redirectUri: document.baseURI });
  };

  return (
    <div className="App">
      <BalanceProvider>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
            <Route
              path="/webstory"
              name="webstory"
              element={
                <PrivateRoute>
                  <WebStoryCreatornew />
                </PrivateRoute>
              }
            />
            <Route
              path="/"
              name="webstory"
              element={
                <PrivateRoute>
                  <WebStoryCreatornew />
                </PrivateRoute>
              }
            />
          </Routes>
        </Suspense>
      </BalanceProvider>
    </div>
  );
}

export default App;
