import { useKeycloak } from '@react-keycloak/web'
import { useEffect } from 'react'
import { redirect } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const { keycloak } = useKeycloak()

  const isLoggedIn = keycloak.authenticated

  console.log(isLoggedIn)

  return isLoggedIn ? children : null
}

export default PrivateRoute
