import React from 'react'
import { createContext, useState } from 'react'

export const BalanceContext = createContext()

export const BalanceProvider = ({ children }) => {
  const initialBalance = parseInt(localStorage.getItem('balance')) || 10
  const [balance, setBalance] = useState(initialBalance)

  return (
    <BalanceContext.Provider value={{ balance, setBalance }}>{children}</BalanceContext.Provider>
  )
}
