import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const RedirectToLogin = () => {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if(keycloak && !keycloak.authenticated) {
      keycloak.logout();
    }
  }, [keycloak]);

  return null;
};

export default RedirectToLogin;