import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer, CFormFeedback, CSpinner } from '@coreui/react'
import { useState, useEffect } from 'react'
import {
  CModalHeader,
  CModal,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CForm,
  CFormInput,
  CCol,
  CAvatar,
} from '@coreui/react'
import coinIcon from './../assets/images/icons/icons8-coins-64.svg'

// routes config
import routes from '../routes'
import { deductibleCoins } from 'src/cost_constant'

const AppContent = ({
  visible,
  setVisible,
  balance,
  setBalance,
  handleSubmit,
  coinsToPurchase,
  setCoinsToPurchase,
}) => {
  const [validInput, setValidInput] = useState(true)

  useEffect(() => {
    if (balance < deductibleCoins.genApiCall) {
      setVisible(true) // show purchase coins form if balance is zero
    }
  }, [balance])

  const handleFormSubmit = () => {
    if (coinsToPurchase <= 0 || isNaN(coinsToPurchase)) {
      setValidInput(false)
    } else {
      handleSubmit()
    }
  }

  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        <>
          <CModal
            backdrop="static"
            visible={visible}
            onClose={() => setVisible(false)}
            aria-labelledby="purchaseCoinsForm"
          >
            <CModalHeader>
              <CAvatar src={coinIcon} size="lg" />
              <CModalTitle id="purchaseCoinsForm">Purchase Coins</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CForm className="row g-3">
                <CCol md={5}>
                  <CFormInput
                    type="number"
                    placeholder="Enter number of coins to purchase"
                    value={coinsToPurchase}
                    onChange={(e) => {
                      setCoinsToPurchase(e.target.value)
                      setValidInput(true)
                    }}
                    pattern="[0-9]*"
                    invalid={!validInput}
                  />
                  {/* Validation error message */}
                  {!validInput && <CFormFeedback>Please enter a valid price</CFormFeedback>}
                </CCol>
              </CForm>
            </CModalBody>
            <CModalFooter>
              <CButton color="primary" onClick={handleFormSubmit}>
                Submit
              </CButton>
              <CButton color="secondary" onClick={() => setVisible(false)}>
                Close
              </CButton>
            </CModalFooter>
          </CModal>
        </>

        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            )
          })}
          <Route path="/" element={<Navigate to="dashboard" replace />} />
        </Routes>
      </Suspense>
    </CContainer>
  )
}
export default React.memo(AppContent)
