import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Photogallery = React.lazy(() => import('./views/pages/photogallery/Photogallery'))
const Typography = React.lazy(() => import('./views/pages/shortvideo/typography/Typography'))
const Webstory = React.lazy(() => import('./views/pages/webstory/webstory'))
const Article = React.lazy(() => import('./views/pages/article/Article'))
const Photostory = React.lazy(() => import('./views/pages/photostory/Photostory'))
const CreateNew = React.lazy(() => import('./views/pages/CreateNew/CreateNew'))
const TrendingWords = React.lazy(() => import('./views/pages/trendingSearch/trendingWords'))
const GoogleTrends = React.lazy(() => import('./views/pages/googleSearch/googleTrends'))
const GenerateArticle = React.lazy(() => import('./views/pages/trendingSearch/generateArticle'))
const UserEvents = React.lazy(() => import('./views/userEvents/userEvents'))
const ProductWebstory = React.lazy(() => import('./views/pages/productWebstories/productWebstories')) 

const routes = [
  { path: '/', name: 'Home', element: Webstory },
  { path: '/dashboard', name: 'Studio', element: Dashboard },
  { path: '/pages/photogallery', name: 'Photo Gallery', element: Photogallery },
  { path: '/pages/CreateNew', name: 'Create New', element: CreateNew },
  { path: '/pages/for-product', name: 'For Product', element: ProductWebstory },
  { path: '/pages/photostory', name: 'From Photo story', element: Photostory },
  { path: '/pages/webstory', name: 'From Web story', element: Webstory },
  { path: '/pages/article', name: 'From text Article', element: Article },
  { path: '/pages/trending-search', name: 'From Breaking Search', element: TrendingWords },
  { path: '/pages/google-search', name: 'Google Realtime Trends', element: GoogleTrends },
  { path: '/pages/google-search/article', name: 'Article', element: GenerateArticle },
  { path: '/pages/trending-search/article', name: 'Article', element: GenerateArticle },
  { path: '/userEvents', name: 'User Dashboard', element: UserEvents },
  { path: '/pages/shortvideo/typography', name: 'Short-Videos', element: Typography },
]

export default routes
